<script>
/* COMPOSANTS */
import MultiSelect from 'primevue/multiselect';
import AkCalendar from '@components/v2/input/AkCalendar.vue';
import AkAutocomplete from '@components/v2/input/AkAutocomplete';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import reservationSiteConst from '@mixins/const/reservationSiteConst';
import reservationAutocompleteMixin from '@mixins/autocomplete/reservationAutocompleteMixin';

/* SERVICES */

export default {
  components: {
    MultiSelect,
    AkCalendar,
    AkAutocomplete,
    AkButtonFooterSidebarFilter,
  },
  mixins: [utilsMixin, sidebarMixin, permissionsMixin, reservationSiteConst, reservationAutocompleteMixin],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    reservationSiteConstForFilter() {
      return this.reservationSiteConstDefinedReview;
    },
  },
  methods: {
    itemSelect() {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.reservationAutocomplete) this.$refs.reservationAutocomplete.reset();
      this.callback[1]();
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent>
    <AkAutocomplete
      ref="reservationAutocomplete"
      v-model="currentValue.reservationId"
      :inline="true"
      @item-select="itemSelect"
      :label="$t('reservation.one')"
      option-value="id"
      option-label="display"
      :init-method="this.autocompleteReservationById"
      :search-method="this.autocompleteReservation"
      :search-object="{accommodationId: this.currentValue.accommodationId}" />

    <AkCalendar
      @valueChange="itemSelect"
      v-model="currentValue.submittedAt"
      inline
      :placeholder="$t('review.submitted_at')"
      selection="range"
      :maxDate="new Date()" />
    <MultiSelect
      @change="itemSelect"
      v-model="currentValue.reservationSites"
      :placeholder="$t('reservation.reservation_site')"
      :options="reservationSiteConstForFilter"
      option-label="label"
      option-value="value"
      class="form-control"
      inline />

    <AkButtonFooterSidebarFilter :validateLabel="$t('search.search')" @action="search" @reset="reset" />
  </form>
</template>
